<template>
  <v-card
    color="#F4F9FF"
    style="display:flex; flex-direction: row; height: 100%; width: 100%"
  >
    <v-layout style="display: flex; flex-direction: column;">
      <label class="item-title">Password</label>
      <label class="email-title ml-10 mb-5">
        In order to protect your account, make sure your password is longer than
        6 characters.
      </label>
      <v-row class="px-6">
        <v-col class="px-3" md="6" cols="12">
          <v-text-field
            v-model="oldPassword"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            :rules="[rules.min]"
            @click:append="showPassword = !showPassword"
            @change="onChange"
            label="Old Password"
            dense
            rounded
            outlined
            persistent-hint
            background-color="white"
            class="pb-0 mb-0 mt-3"
          />
          <v-text-field
            v-model="newPassword"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            :rules="[rules.min]"
            @click:append="showPassword = !showPassword"
            @change="onChange"
            label="New Password"
            dense
            rounded
            outlined
            persistent-hint
            background-color="white"
            class="pb-0 mb-0 mt-3"
          />
          <v-text-field
            v-model="confirmPassword"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            :rules="[rules.min]"
            @click:append="showPassword = !showPassword"
            @change="onChange"
            label="Confirm Password"
            dense
            rounded
            outlined
            persistent-hint
            background-color="white"
            class="pb-0 mb-0 mt-3"
          />
        </v-col>
      </v-row>
      <div style="display:flex; justify-content:flex-end; flex-direction: row;">
        <v-btn
          rounded
          width="200"
          color="primary"
          class="mb-3 mr-3 mb-10"
          @click="onUpdate"
          :loading="loading"
          v-text="'Update'"
        />
      </div>
    </v-layout>
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </v-card>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        phone: (value) => {
          const pattern = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
          return pattern.test(value) || "Invalid phone number.";
        },
        min: (v) => (v && v.length >= 8) || "Min 8 characters",
      },
      showPassword: false,
      errorMessage: null,
      snackbar: false,
      loading: false,
      confirmPassword: "",
      oldPassword: "",
      newPassword: "",
    };
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
    }),
  },
  methods: {
    ...mapActions("auth", {
      updatePassword: "updatePassword",
    }),
    onUpdate() {
      if (this.newPassword != this.confirmPassword) {
        this.errorMessage = "Password does not match";
        this.snackbar = true;
        return;
      }
      var params = {
        old_password: this.oldPassword,
        new_password: this.newPassword,
      };

      this.loading = true;
      this.updatePassword(params)
        .then(() => {
          this.loading = false;
          this.showErrorMessage("Password updated successfully");
        })
        .catch((error) => {
          this.loading = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.showErrorMessage(error.response.data.message);
          }
        });
    },
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
    onChange() {
      this.errorMessage = null;
    },
  },
};
</script>
<style>
.item-title {
  color: #6e3dc6;
  font-family: "Poppins-Bold";
  font-size: 25px;
  padding-top: 20px;
  padding-left: 40px;
  padding-bottom: 10px;
}
.name-title {
  color: #6e3dc6;
  font-family: "Poppins-Bold";
  font-size: 20px;
}
.email-title {
  color: #6e3dc6;
  font-family: "Poppins-Medium";
  font-size: 15px;
}
.v-text-field >>> input#map::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: transparent;
}
.v-text-field >>> input#map::-moz-placeholder {
  /* Firefox 19+ */
  color: transparent;
}
.v-text-field >>> input#map:-ms-input-placeholder {
  /* IE 10+ */
  color: transparent;
}
.v-text-field >>> input#map:-moz-placeholder {
  /* Firefox 18- */
  color: transparent;
}
.v-text-field >>> input#map::placeholder {
  color: transparent;
}

.v-sheet::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-sheet::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-sheet::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-sheet::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}
</style>
